import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';



import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ModellerGuard  {
  constructor(private _authService: AuthService, private _router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this._authService.userHasAdminRole || this._authService.userHasModellerRole) {
      return true;
    } else {
      return this._router.parseUrl('');
      // this._router.navigate([''])
    }
  }

  canMatch(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return (this._authService.userHasAdminRole || this._authService.userHasModellerRole)!;
  }
}
